import React, { useState, useEffect } from "react"
export const PageComponent = ({ children }) => {
  const [LazyComponent, setLazyComponent] = useState(null)
  const loadLazyComponent = async () => {
    if (LazyComponent !== null) {
      return
    }

    // import default export of `lazy.js` module
    const { default: lazyModule } = await import("@components/Snipcart")
    // create React component so it can be rendered
    const LazyElement = React.createElement(lazyModule, {
      // pass props to component here
    })
    setLazyComponent(LazyElement)
  }

  useEffect(() => {
    setTimeout(() => {
      loadLazyComponent()
    }, 4500)
  }, []) //eslint-disable-line
  return (
    <React.Fragment>
      {LazyComponent}
      {children}
    </React.Fragment>
  )
}
