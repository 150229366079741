import "./src/css/tailwind.css"
import Helmet from "react-helmet"
import { PageComponent } from "./src/components/wrapPageComponent"
const React = require("react")

// const addScript = url => {
//   const script = document.createElement("script")
//   script.src = url
//   script.async = true
//   document.body.appendChild(script)
// }

// export const onClientEntry = () => {
//   window.onload = () => {
//     addScript("https://cdn.snipcart.com/themes/v3.0.8/default/snipcart.js")
//   }
// }

// trigger an immediate page refresh when an update is found
export const onServiceWorkerUpdateReady = () => window.location.reload()

// export const wrapPageElement = ({ element, props }) => {
//   const Layout = element.type.Layout ?? React.Fragment
//   return (
//     <Layout {...props}>
//       <Helmet>
//         <script
//           async
//           src="https://cdn.snipcart.com/themes/v3.2.1/default/snipcart.js"
//         ></script>
//       </Helmet>
//       <div
//         hidden
//         id="snipcart"
//         data-api-key="NGU4Y2Y1ZDAtZDg5My00NTVjLWFmNzMtMGQ0NGNlZjJlMjAwNjM3MTM4Mzk3MDM3NzQxOTc5"
//       ></div>
//       {element}
//     </Layout>
//   )
// }

export const wrapPageElement = ({ element }) => {
  return <PageComponent>{element}</PageComponent>
}
